<template lang="pug">
NuxtLink(:to="urlx")
    .flex.flex-1.gap-2
        .flex.self-center
            //- UIcon.w-7.h-7.text-red-300(name="i-mdi-shield-lock-outline")
            UAvatar(
                size="xs"
                :ui="{rounded: 'rounded-md'}"
                src="https://github-production-user-asset-6210df.s3.amazonaws.com/31319237/284836243-bb4b233c-db31-4e76-a282-ac015ff63e5e.jpg"
            )
        .flex.gap-2.flex-1.self-center
            .font-bold.text-md 110RED
            //- .flex
                UBadge(size="xs" color="red" variant="solid") v1

    //- img(src="https://s3.us-east-005.backblazeb2.com/101red/global/jaAeBeUU7jjDCs6mguuV-A.png" class="h-8")
</template>
<script setup lang="ts">
const route = useRoute();
const urlx = ref('')

if (route.path.includes("/admin")) {
    urlx.value = "/admin"
} else {
    urlx.value = "/"
}

</script>
